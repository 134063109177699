module.exports = {
    headline: "Range of services",
    desc_label: "Description",
    terms_label: "Terms & Conditions",
    annually: 'per year',
    quarterly: 'per quarter',
    monthly: 'per month',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'per month',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "With a subscription to the \"Aquarea Service+ Premium\" plan, you have one less thing to worry about. You will get a service plan that includes a two-year warranty extension (5 years in total), 2 annual visits supervised by the Panasonic team and a report delivered periodically. If a reported fault is not resolved remotely, a technician will be on site within 24 working hours*.",
            disclaimer: "*You can check the coverage of our technical service on our telephone number 900 82 87 87. When you purchase the \"Aquarea Service+ Premium\" maintenance plan, you will receive the \"Aquarea Service+ Smart\" plan until the Panasonic technician validates that the technical requirements of your request have been met. If these technical requirements are not met, you will benefit from the advantages of the \"Aquarea Service+ Smart\" plan. As long as your installation is not approved for the \"Aquarea Service+ Premium\" plan, you will continue to be charged the \"Aquarea Service+ Smart\" price.",
            info: "Enjoy a pleasant temperature in your home.",
            good_to_know_title: "Good to know",
            good_to_know_list: [
                {
                    label: "You can cancel annually"
                },
            ],
            link: "Download all conditions",
            linkHref: "/en_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Premium Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/5_extension_warranty.svg",
                    alt: "",
                    label: "Total warranty 5 years",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Spare parts and labour included",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Remote monitoring and periodical reports",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Phone assistance and service appointment within 24 working hours",
                },
                {
                    icon: "/image/icons/services/2_maintenance_a_year.svg",
                    alt: "",
                    label: "2 annual maintenance visits",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'per month',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "With a subscription to the \"Aquarea Service+ Smart\" Plan, you have one less thing to worry about. You will get a service plan that includes a one-year warranty extension (4 years in total), an annual maintenance visit by one of our technicians and a report delivered periodically. Thanks to the \"Service Cloud\" your fan heater is monitored by the Panasonic team. If it is not possible to solve it remotely, a technician will come within 48 working hours*.",
            disclaimer: "*You can check the coverage of our technical service on our telephone number 900 82 87 87.",
            info: "Enjoy the pleasant temperature in your home.",
            good_to_know_title: "Good to know",
            good_to_know_list: [
                {
                    label: "You can cancel annually"
                },
            ],
            link: "Download all conditions",
            linkHref: "/en_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Smart Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/4_extension_warranty.svg",
                    alt: "",
                    label: "Total warranty 4 years",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Spare parts and labour included",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Remote monitoring",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "1 annual maintenance visit",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_comfort.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_comfort.svg",
            subheadline: 'per month',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "With a subscription to the \"Aquarea Service+ Comfort\" plan, you get a service plan that includes an annual maintenance visit by one of our technicians and a report delivered periodically. If you report a fault to us, we will try to solve the problem by telephone. If a remote solution is not possible, a technician will come within 48 working hours*.",
            info: "Enjoy a pleasant temperature in your home.",
            good_to_know_title: "It is interesting to know",
            good_to_know_list: [
                {
                    label: "You can cancel annually"
                },
            ],
            link: "Download all terms and conditions",
            linkHref: "/en_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Comfort Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Phone assistance and service appointment within 48 working hours",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "1 annual maintenance visit",
                }
            ]
        },

    ],
};
