import axios from "axios";
import {getHeaderForApiRequest} from "../dataAccessHelper";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_BASE_URL;

export function getAccount(authToken) {
    return axios.get(BASE_URL + '/account', {
        headers: getHeaderForApiRequest(authToken)
    });
}

export async function changePasswordWithToken(body, authToken) {
    return axios.put(BASE_URL + '/account/password-change', body, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export async function putRegisterUnitData(unitId, body, authToken) {
    const headers = getHeaderForApiRequest(authToken)
    return axios.put(BASE_URL + '/account/unit/' + unitId, body,{ headers });
}