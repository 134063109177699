    module.exports = {
    headline: "Reparationsbeställning",
    thanks: "Tack för att du har kontaktat Aquarea+ Service!",
    text: "Reparationsbeställningen har slutförts. Du kommer att få din faktura i din e-postinkorg.",
    invoiceAmountText: "Fakturabelopp: ",
    paymentChargeText: "Betalningsavgift den: ",
    isPaidText: "Din faktura har behandlats framgångsrikt.",
    payLink: "Vänligen betala online här",
    payButton: "Betala nu"
};
