    module.exports = {
    headline: "Pedido de reparación",
    thanks: "Gracias por contactar con Aquarea+ Service!",
    text: "Su pedido de reparación ha sido finalizado con éxito. Recibirá la factura en su correo electrónico.",
    invoiceAmountText: "Importe de la factura: ",
    paymentChargeText: "Cargo de pago en: ",
    isPaidText: "Su factura ha sido procesada con éxito.",
    payLink: "Pague en línea aquí",
    payButton: "Pagar ahora"
};
