import React from 'react';
import Footer from "../components/footer";
import { OptionalValue } from "../components/layout/detailEntry";
import { Page, PageBreadcrumb, PageHeader, PageHeadline } from "../components/layout/page";
import { Section, SectionSubheadline } from "../components/layout/section";
import Menu from "../components/menu";
import AccountInformation from "../components/myaccount/accountInformation";
import Reports from "../components/myaccount/reports";
import { getReports } from "../lib/dataAccess/customer/reportsRestEndpoint";
import Contract from "../components/myaccount/contract";
import InvoiceHistory from "../components/myaccount/invoiceHistory";
import NoServicePackage from "../components/myaccount/noServicePackage";
import YourProtectionPlan from "../components/myaccount/yourProtectionPlan";
import RepairOrder from "../components/myaccount/repairOrder";
import { getAccount } from "../lib/dataAccess/accountService";
import i18 from "../lib/i18n";
import {
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    removeSessionStorage
} from "../lib/sessionStorage";
import { getCartFromServer } from '../lib/dataAccess/checkout/cartRestEndpoint';
import { postRepairOrders } from '../lib/dataAccess/checkout/orderRestEndpoint';
import { Link } from "react-router-dom";
import {CartContext} from '../lib/contexts/cartContext';
import { BtnGroup } from "../components/layout/buttons";
import { BAD_REQUEST_STATUS, UNAUTHORIZED_STATUS } from '../lib/constants';

const t = i18.namespace("MyAccount");

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
export default class MyAccount extends React.Component {
    static contextType = CartContext

    constructor(props) {
        super(props);

        this.state = {
            account: null,
            repairOrders: null,
            cart: null,
            reports: null
        };
        this.getAccount = async () => {
            await getAccount(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
              this.setState({ account: result.data, isAccountLoaded: true });
            })
            .catch((e) => {
              console.error(e);
              if (e?.response?.status === UNAUTHORIZED_STATUS) {
                removeSessionStorage();
                this.setState({ account: null, isAccountLoaded: false });
                this.props.history.push("/" + countryCode + "/home/login");
              }
            });
        };
        this.getCart = async () => {
            await getCartFromServer(null, getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
                const {setCartContext} = this.context;
                setCartContext(result.data);
                this.setState({ cart: result.data, isCartLoaded: true });
            })
            .catch(e => {
                console.error(e);
                if (e?.response?.status === UNAUTHORIZED_STATUS || e?.response?.status === BAD_REQUEST_STATUS) {
                  removeSessionStorage();
                  this.setState({ cart: null, isCartLoaded: false });
                  this.props.history.push("/" + countryCode + "/home/login");
                }
              });
        };
        this.getReports = async () => {
            await getReports(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
                this.setState({ reports: result.data});
            })
            .catch((e) => {
                console.error(e);
            });
        };
        this.getRepairOrders = async () => {
            if (!this.state.repairOrders) {
                postRepairOrders(this.state.account.email, getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME))
                    .then((result) => {
                        this.setState({repairOrders: result.data})
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        }
    }

    componentDidMount() {
        this.getAccount();
        this.getCart();
        this.getReports();
    }

    drawRepairOrders() {
        return <section className={"repair-order"}>
            {!!this.state.repairOrders ? this.state.repairOrders.map((order, index) =>
                <RepairOrder index={index} account={this.state.account} order={order}/>) : ""}
        </section>
    }

    getContracts(){
        let contracts = [];
        this.state.account.installedBaseUnits.forEach((baseUnit, index) =>{
            if (baseUnit.orderData?.orderNumber !== undefined) {
                contracts.push(
                    <Section key={baseUnit.id} id={"contract"+index}>
                        <YourProtectionPlan account={this.state.account} baseUnit={baseUnit}/>
                        <Section id={"contractbaseunit"+index}>
                            <Contract baseUnit={baseUnit} />
                        </Section>
                        <OptionalValue condition={this.state.reports && this.state.reports.length > 0} emptyValue=''>
                            <Section id="reports">
                                <Reports account={this.state.account} reports={this.state.reports} index={index} />
                            </Section>
                        </OptionalValue>
                        <OptionalValue condition={baseUnit.orderData !== undefined} emptyValue= "">
                            <Section id={"invoice_history"+index}>
                                <SectionSubheadline>{t("invoiceHistory")}</SectionSubheadline>
                                <InvoiceHistory orderData={baseUnit?.orderData} orderId={baseUnit?.orderData?.orderId} />
                            </Section>
                        </OptionalValue>
                    </Section>
                )
            }
        })
        return contracts;
    }
    render() {
        if (!this.state.isAccountLoaded || !this.state.isCartLoaded) {
            return null;
        }
        this.getRepairOrders()
        return(
            <Page>
                <Menu/>
                <div className="my-account container">
                    <PageHeader>
                        <PageBreadcrumb />

                        <PageHeadline className="my-account_headline">
                            {t("welcome") + this.state.account.firstName + " " +  this.state.account.lastName}
                        </PageHeadline>
                    </PageHeader>

                    <div className="d-flex">
                        <aside className="my-account_sidenav sidenav sidenav--sticky">
                            <nav className="sidenav_container">
                                <ul className="sidenav_nav">
                                    <OptionalValue condition={this.state.account.installedBaseUnits[0]?.orderData} emptyValue=''>
                                        <li className="sidenav_nav_item" key={t("yourProtectionPlan")}>
                                            <a className="sidenav_nav_link" href="#contract0">{t("yourProtectionPlan")}</a>
                                        </li>
                                        <li className="sidenav_nav_item" key={t("contract")}>
                                            <a className="sidenav_nav_link" href="#contractbaseunit0">{t("contract")}</a>
                                        </li>
                                        <li className="sidenav_nav_item" key={t("invoiceHistory")}>
                                            <a className="sidenav_nav_link" href="#invoice_history0">{t("invoiceHistory")}</a>
                                        </li>
                                    </OptionalValue>
                                    <li className="sidenav_nav_item" key={t("accountInformation")}>
                                        <a className="sidenav_nav_link" href="#account_information">{t("accountInformation")}</a>
                                    </li>
                                </ul>
                            </nav>
                        </aside>

                        <div className="my-account_content">
                        <OptionalValue condition={this.state.cart.lineItems.length > 0 && this.state.cart.type !== "UPGRADE"} emptyValue=''>
                                <Section id="finish_checkout" key={t("finishCheckout")}>
                                    <SectionSubheadline>{t("finishCheckout")}</SectionSubheadline>
                                    <BtnGroup>
                                        <Link
                                            className="btn-primary"
                                            to={'/'+ countryCode +'/home/checkout/payment'}
                                        >
                                            {t("gotoCheckout")}
                                        </Link>
                                    </BtnGroup>
                                </Section>
                            </OptionalValue>
                            <OptionalValue condition={this.state.cart.lineItems.length > 0 && this.state.cart.type === "UPGRADE"} emptyValue=''>
                                <Section id="finish_checkout" key={t("finishCheckout")}>
                                    <SectionSubheadline>{t("finishCheckout")}</SectionSubheadline>
                                    <BtnGroup>
                                        <Link
                                            className="btn-primary"
                                            to={'upgrade/checkout'}
                                        >
                                            {t("finishUpgrade")}
                                        </Link>
                                    </BtnGroup>
                                </Section>
                            </OptionalValue>
                            <OptionalValue condition={this.state.account.installedBaseUnits.length > 0} emptyValue=''>
                                {this.drawRepairOrders()}
                                {this.getContracts()}
                            </OptionalValue>
                            <OptionalValue condition={!(this.state.account.installedBaseUnits.length > 0)} emptyValue=''>
                                <Section id="no_service_package" key={t("noServicePackage")}>
                                    <SectionSubheadline>{t("noServicePackage")}</SectionSubheadline>
                                    <NoServicePackage account={this.state.account} />
                                </Section>
                            </OptionalValue>
                            <Section id="account_information">
                                <SectionSubheadline>{t("accountInformation")}</SectionSubheadline>
                                <AccountInformation account={this.state.account} />
                            </Section>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Page>
        )
    }
}
