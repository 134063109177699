import React, { useEffect,useContext } from 'react'
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Breadcrumbs from "../lib/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";
import { scrollToPos } from '../lib/scrollToPos';
import { useMemo } from 'react';
import {AuthContext} from "../lib/contexts/authContext";
import { useParams } from "react-router-dom";
import { setUpdateInfoPending } from "../lib/dataAccess/checkout/orderRestEndpoint";
import {
  getSessionStorageItem,
  COUNTRY_CODE_NAME,
} from "../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "en_IE";
const t = i18.namespace("UpdateExpiry");
const errMap = {
  update_expired:'expired',
  update_pending: 'pending',
  update_failure: 'failure',
  update_cancel:'cancel'
}

const UpdateFailure = () => {

  const history = useHistory();
  const decodedError = useMemo(() => {
    let errMessage = t("error");
    Object.keys(errMap).forEach(err => {
      if (history.location.pathname.includes(err)) {
        console.log(errMap[err]);
        errMessage = t(errMap[err]);
      }
    });
    return errMessage;
  }, [history.location.pathname]);
  const { authContext } = useContext(AuthContext);
  const { orderId } = useParams();
  
  
  useEffect(() => {
    scrollToPos(0, 0);
    let infoStatus;
    if (!!orderId && authContext?.accountData?.installedBaseUnits) {
      infoStatus = authContext?.accountData?.installedBaseUnits
        ?.filter(
          (installedBaseUnit) =>
            installedBaseUnit?.orderData?.orderId === orderId
        )
        .map((filteredUnit) => {
          return filteredUnit?.orderData?.infoStatus;
        });
      const { pending, renew, failed } = infoStatus[0];
      if (!pending && !renew && !failed) {
        history.replace("/" + countryCode + "/update_failure");
        return;
      }
      setUpdateInfoPending(
        {
          orderId: orderId,
          failedFlag: true,
        },
        authContext?.authToken
      ).then(history.replace("/" + countryCode + "/update_failure"));
    }
  }, [
    authContext?.accountData?.installedBaseUnits,
    authContext?.authToken,
    history,
    orderId,
  ]);

  return (
    <>
      <div className="no-match-404">
        <Menu />
        <div className="container">
          <div className="page_header">
            <section className="breadcrumbs">
              <Breadcrumbs />
            </section>
          </div>

          <div className="no-match-404_wrapper">
            <div className="no-match-404_content">
              <div className="icon icon_x-circle" />
              <p className="no-match-404_text">{decodedError}</p>
              <Link className="btn btn_line" to={t("link_href")}>{t("link")}</Link>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  )
}

export default UpdateFailure;
