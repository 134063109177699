import React from 'react';
import i18 from "../../lib/i18n";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SmallSectionHeadline } from "../layout/section";
import { BtnGroup } from "../layout/buttons";
import {
    getSessionStorageItem,
    COUNTRY_CODE_NAME,
} from "./../../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

const t = i18.namespace("RepairOrder");

const repairOrder = ({index, account, order}) => {

    const invoiceAmount = order?.totalGross?.amount + " " + order?.totalGross?.currency;
    const paymentChargeDate = order?.paymentCharge;
    const pathname = '/'+ countryCode +'/home/repair_payment_method/' + order.orderId;

    // TODO: CBDWA-202: move to separate enum class
    const MANUAL = "manual"
    const AUTOMATIC = "automatic"
    const PAID = "paid"
    const COMPLETE = "COMPLETE"

    const paymentState =
        order.status === COMPLETE ? PAID : !order.paymentTrigger  ? MANUAL : order.paymentTrigger;

    function checkState() {
        return paymentState === MANUAL || paymentState !== AUTOMATIC
    }

    return (
            <div className={checkState() ? "repair-order-paid" : "repair-order-pending"} id={"contract-repair-order-"+index}>
                <div className={"wrapper"}>
                    {<SmallSectionHeadline>
                    {t("headline")}
                </SmallSectionHeadline>}

                    <p>{order.orderNumber}</p>

                    <div className={"text"}>
                        {t("thanks")}<br/>
                        {t("text")}<br/>
                        {t("invoiceAmountText") + invoiceAmount}<br/>
                        {t("paymentChargeText") + paymentChargeDate}<br/><br/>
                        {paymentState === PAID ? t("isPaidText") : ""}
                        {checkState() ?
                            <>
                                <br/>
                                <Link className={"repair-order-link"}
                                      to={pathname}>
                                    {t("payLink")}
                                </Link>
                            </>
                            : ""
                        }
                    </div>
                    {checkState() ?
                        <BtnGroup className="detail_entry">
                            <Link className="btn-quarternary"
                                  to={pathname}>
                                {t("payButton")}
                            </Link>
                        </BtnGroup>
                        : ""
                    }
                </div>
            </div>)
}

export default repairOrder;
