module.exports = {
    headline: "Planes de mantenimiento",
    desc_label: "Descripción",
    terms_label: "Términos y condiciones",
    annually: 'anuales',
    quarterly: 'por trimestre',
    monthly: 'mensuales',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'por mes',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "Con una suscripción al plan \"Aquarea Service+ Premium\", tienes una preocupación menos. Obtendrás un plan de servicios que incluye la ampliación de dos años de garantía (5 años en total), 2 visitas anuales supervisadas por parte del equipo Panasonic y un informe entregado periódicamente. Si una avería comunicada no fuera solucionada de manera remota, un técnico acudirá en un plazo de 24h* laborables.",
            disclaimer: "*Puedes verificar la cobertura de nuestro servicio técnico en nuestro teléfono 900 82 87 87. \n \n Al adquirir el plan de mantenimiento \"Aquarea Service+ Premium\", recibirás el plan \"Aquarea Service+ Smart\" hasta que el técnico de Panasonic valide el cumplimiento de los requisitos técnicos de tu solicitud. Si no se cumplen estos requisitos técnicos, disfrutarás de las ventajas del plan \"Aquarea Service+  Smart\" . Mientras que tu instalación no sea aprobada para el plan \"Aquarea Service+ Premium\", se te seguirá cobrando el precio de \"Aquarea Service+ Smart\".",
            info: "Disfruta de una agradable temperatura en tu hogar.",
            good_to_know_title: "Es interesante saber",
            good_to_know_list: [
                {
                    label: "Puedes cancelar anualmente"
                },
            ],
            link: "Descarga todas las condiciones",
            linkHref: "/es_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Ir a la comparación"
            },
            btn_book: {
                href: "",
                label: "Pedir Aquarea Service + Premium"
            },
            iconList: [
                {
                    icon: "/image/icons/services/5_extension_warranty.svg",
                    alt: "",
                    label: "5 años de garantía total",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Mano de obra y desplazamiento incluídos",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Monitorización remota e informes periódicos",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Asistencia telefónica y tiempo de respuesta 24h* laborables",
                },
                {
                    icon: "/image/icons/services/2_maintenance_a_year.svg",
                    alt: "",
                    label: "2 Mantenimientos anuales",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'por mes',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "Con una suscripción al Plan \"Aquarea Service+ Smart\", tienes una preocupación menos. Obtendrás un plan de servicios que incluye  ampliación de un año de garantía (4 años en total), una visita de mantenimiento anual por parte de uno de nuestros técnicos y un informe entregado periódicamente. Gracias al \"Service Cloud\" tu aerotermia está monitorizada por parte del equipo de Panasonic. Si no fuera posible solucionarlo de manera remota,  un técnico acudirá en un plazo de 48h* laborables.",
            disclaimer: "*Puedes verificar la cobertura de nuestro servicio técnico en nuestro teléfono 900 82 87 87.",
            info: "Disfruta de la agradable temperatura de tu hogar.",
            good_to_know_title: "Es interesante saber",
            good_to_know_list: [
                {
                    label: "Puedes cancelar anualmente"
                },
            ],
            link: "Descarga todas las condiciones",
            linkHref: "/es_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Ir a la comparación"
            },
            btn_book: {
                href: "",
                label: "Pedir Aquarea Service+ Smart"
            },
            iconList: [
                {
                    icon: "/image/icons/services/4_extension_warranty.svg",
                    alt: "",
                    label: "4 años \nde garantía",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Mano de obra y desplazamiento incluído",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Monitorización remota",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Mantenimiento anual",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_comfort.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_comfort.svg",
            subheadline: 'por mes',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "Con una suscripción al plan \"Aquarea Service+ Comfort\", obtendrás un plan de servicios que incluye una visita de mantenimiento anual por parte de uno de nuestros técnicos y un informe entregado periódicamente.  En caso de que nos comunique una avería intentaremos solucionar el problema telefónicamente. Si no fuera posible una solución remota, un técnico acudirá en un plazo de 48 horas* laborales.",
            info: "Disfruta de la agradable temperatura de tu hogar.",
            good_to_know_title: "Es interesante saber",
            good_to_know_list: [
                {
                    label: "Puedes cancelar anualmente"
                },
            ],
            link: "Descargar todas las condiciones",
            linkHref: "/es_ES/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Ir a la comparación"
            },
            btn_book: {
                href: "",
                label: "Pedir Aquarea Service+ Comfort"
            },
            iconList: [
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Asistencia telefónica y tiempo de respuesta 48h* laborables",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Mantenimiento anual",
                }
            ]
        },

    ],
};
