    module.exports = {
    headline: "Repair Order",
    thanks: "Thank you for contacting Aquarea+ Service!",
    text: "Your repair order has been successfully finished. You will find your invoice in your email inbox.",
    invoiceAmountText: "Invoice amount: ",
    paymentChargeText: "Payment charge on: ",
    isPaidText: "Your invoice has been successfully processed.",
    payLink: "Please pay online here",
    payButton: "Pay now"
};
