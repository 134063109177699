module.exports = {
    headline: "Reparationsordre",
    thanks: "Tak for din henvendelse til Aquarea+ Service!",
    text: "Din reparationsordre er blevet afsluttet med succes. Du vil modtage din faktura i din e-mail indbakke.",
    invoiceAmountText: "Fakturabeløb: ",
    paymentChargeText: "Betalingsafgift den: ",
    isPaidText: "Din faktura er blevet behandlet med succes.",
    payLink: "bedes du betale online her",
    payButton: "Betal nu"
};
