    module.exports = {
    headline: "Ordine di riparazione",
    thanks: "Grazie per aver contattato Aquarea+ Service!",
    text: "Il tuo ordine di riparazione è stato completato con successo. Finirà la sua fattura nella sua casella di posta elettronica.",
    invoiceAmountText: "Importo della fattura: ",
    paymentChargeText: "Addebito del pagamento su: ",
    isPaidText: "La sua fattura è stata elaborata con successo.",
    payLink: "Si prega di pagare online qui",
    payButton: "Paga adesso"
};
