import axios from "axios";
import {getHeaderForApiRequest} from "../dataAccessHelper";

const PAYMENT_BASE_URL = process.env.REACT_APP_API_PAYMENT_ENDPOINT_BASE_URL;
const CHECKOUT_BASE_URL = process.env.REACT_APP_API_CHECKOUT_ENDPOINT_BASE_URL;

export function submitOrderRequest(cartId, authToken) {
    return axios.post(PAYMENT_BASE_URL + '/worldpay/submit/order/cart', { cartId }, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function submitPaymentUpdateRequest(orderId, paymentMethod, authToken) {
    return  axios.post(PAYMENT_BASE_URL + '/worldpay/submit/order/payment/update',
        { 'orderId': orderId, 'paymentMethod': paymentMethod.toUpperCase() },
        { headers: getHeaderForApiRequest(authToken) });
}

export function deleteOrderRequest(authToken, orderId) {
    // TODO: eigentlich sollte reason optional sein, bitte später rausnehmen
    return axios.post(`${CHECKOUT_BASE_URL}/order/${orderId}/cancel`, { "reason": '' }, {
        headers: getHeaderForApiRequest(authToken),
    });
}

export function getUpgradeOptionsForOrder(orderId, authToken) {
    return axios.get(CHECKOUT_BASE_URL + '/order/' + orderId + '/upgrade', {
        headers: getHeaderForApiRequest(authToken)
    });

    // returns:
    // {
    //     "possibleSkus": [
    //         "contract-heatpump-gold",
    //         "contract-heatpump-silver"
    //     ],
    //     "feeRequired": false
    // }
}

export function queryOrderDowngradeOptions(authToken, orderId) {
    return axios.get(CHECKOUT_BASE_URL + '/fe-order/' + orderId + '/downgrade-options',
        {headers: getHeaderForApiRequest(authToken)}
    );

    // returns:
    //
    //     ["silver", "bronze"]
    //
}

export function markOrderWithDowngradeInfo(authToken, orderId, selectedDowngradeOption) {
    const data = {"createdBy": "CUSTOMER", "downgradeProduct": selectedDowngradeOption}
    return axios.post(CHECKOUT_BASE_URL + '/fe-order/' + orderId + '/mark-downgrade',
        data,
        {headers: getHeaderForApiRequest(authToken)}
    );
}

export function postContractUpgradeForOrder(orderId, sku, authToken) {
    return axios.post(CHECKOUT_BASE_URL + '/order/' + orderId + '/upgrade', { sku }, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function postContractUpgradeForOrderWithCart(cartId, authToken) {
    return axios.post(CHECKOUT_BASE_URL + '/order/finalize-upgrade/'+ cartId , {}, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function getIsCampaignOrdered(orderId, authToken) {
    return axios.post(CHECKOUT_BASE_URL + '/order/is-campaign', {orderId} , {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function postRepairOrders(email, authToken) {
    return axios.post(CHECKOUT_BASE_URL + '/order/repairment', {"email": email}, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function getDiscount(orderId, authToken) {
    return axios.get(CHECKOUT_BASE_URL + '/discount/' + orderId, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function deletePaymetToken(orderId, authToken) {
    return  axios.post(PAYMENT_BASE_URL + '/worldpay/update-expiry/'+ orderId,{},{
        headers: getHeaderForApiRequest(authToken) 
    });
}

export function checkMultipleContracts(orderId, authToken) {
    const url = `${CHECKOUT_BASE_URL}/order/payment-token?orderId=${orderId}`;
    return axios.get(url, {orderId} , {
        headers: getHeaderForApiRequest(authToken)
    });
}
export function setUpdateInfoPending(info, authToken) {
    const url = `${CHECKOUT_BASE_URL}/order/update-flag`;
    return axios.put(url,info , {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function submitOrderLanguage(lang, authToken) {
    return axios.put(CHECKOUT_BASE_URL + '/cart/update-locale', {
        "language":lang
      }, {
        headers: getHeaderForApiRequest(authToken)
    });
}