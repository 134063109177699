module.exports = {
    common: {
        REQUIRED: "Este campo es obligatorio",
        MAX_LENGTH: "Max {{maxLength}} caracteres",
        COMPARE: "Las entradas deben ser idénticas",
        DATE: "Fecha no válida",
        MIN_LENGTH: "Min {{minLength}} dígitos",
        EXACTLY_LENGTH: "Caracteres {{exactlyLength}} exactos",
        EMAIL: "Correo electrónico no válido",
        BACKEND_VALIDATION_ERROR: "Cabecera del servidor",
        ZIPCODE: "Se requieren {{length}} números exactos",
        SWEDISH_ZIPCODE: "{{length}} and only numbers",
        PASSWORD: "Mínimo 8 caracteres, números y letras"
    },
    USER_ALREADY_REGISTERED: "La dirección de correo electrónico ya está registrada",
    REGISTRATION_FAILED: "Se ha producido un error técnico durante el registro. Vuelva a intentarlo más tarde.",
    validationMethods: {
        "required": {
            message: "Este campo es obligatorio",
        },
        "email": {
            message: "Correo electrónico no válido",
        },
        "matching_email_values": {
            message_mail: "Correo electrónico no válido",
            message_not_same: "Las direcciones de correo electrónico deben ser las mismas"
        },
        "password": {
            message: "Contraseña incorrecta",
            message_password: "Contraseña incorrecta",
            message_password_lengths: "Mínimo 8 caracteres, números y letras",
            message_not_same: "Las contraseñas deben ser las mismas"
        },
        "loginError": {
            message: "El nombre de usuario o la contraseña son incorrectos"
        },
        "missingEmail": {
            message: "Correo electrónico perdido"
        },
    },
    optionalValue: "Opcional"
}
